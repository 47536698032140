<template>
 <div>
    <v-card class="mx-auto" max-width="1400">
      <v-img
        class="imgDiv portada white lighten-2"
        style="height:600px;"
        src="https://utaxon.com/img/home1.png"
        lazy-src="https://picsum.photos/id/11/100/60"
        aspect-ratio="1">
        <div
          style="display:center!important;margin-top:200px;font-family: 'Arial', Times, serif;font-weight:bold;font-size:46px;color:white;text-align:center"
        >Abre la puerta a la contabilidad inteligente</div>
      </v-img>
    </v-card>    


    <v-dialog v-model="dialogoDescargas" persistent max-width="730px" style="overflow:hidden!important">
      <v-card style="overflow:hidden!important">
        <v-card-title>
          <span
            class="headline"
            style="width:100%;text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:36px;font-weight:bold;word-break:break-word;"
          >Utax Me</span>
          <div id="mensajeMail" style="text-align:center;font-size:20px;margin-top:10px;word-break: break-word;line-height: 1.5rem;"
          ></div>
        </v-card-title>
        <v-card-text>
          <!-- descarga form -->
          <descarga cols=12></descarga>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="salir"  class="mx-2"  dark  color="error" style="margin-right: 19px!important;margin-top:-30px">
              Salir
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  
  import descarga from '../views/DasboardDetalles/Descarga';
  import operations from '../operations';

  export default {
    name: 'descargas',

    components: {
      descarga
    },
    data: () => ({
    dialogoDescargas:true,
  }),
  methods:{
    salir(){
      window.location = 'https://www.utaxme.com'
    },
   async CatalogosSatForm() {
            console.log('catalogosCFDI....');
            try {
                const response = await operations.catalogosSatForm({
                    rfc: ''
                });
                console.log(response);

                if (response.status == "200") {
                    //combo 1
                    this.$store.state.catalogoPlataformasD = response.data.catalogoPlataformas;
                    console.log(":::::::::::::");

                    this.$store.state.catalogoPlataformasD.splice(0, 0, {aplicaRetencion:'',idPlataforma:'-1',ivaTrasladado:0,plataforma:'Seleccionar...'});
                   
                    console.log("ok catalogos cargados...");
                }
            } catch (error) {
                console.log(error.response);
            }
        },
  },
  mounted(){
    this.CatalogosSatForm();
  }
  }
</script>