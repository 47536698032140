<template>
  <div>

    <!-- https://picsum.photos/id/11/100/60-->
    <v-card class="mx-auto" max-width="1400">
      <v-img
        class="imgDiv portada white lighten-2"
        style="height:600px;"
        src="https://utaxon.com/img/home1.png"
        lazy-src="https://utaxon.com/pcutaxme.png"
        aspect-ratio="1"
      >
        <div
          style="display:center!important;margin-top:200px;font-family: 'Arial', Times, serif;font-weight:bold;font-size:46px;color:white;text-align:center"
        ></div>
      </v-img>
    </v-card>

    <v-dialog v-model="dialogoRegistro" persistent max-width="450px" style="max-height: 100%!important;" >
      <v-card v-if="tradicional"  >
        <v-card-title></v-card-title>
        <span style="position:absolute;text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:26px;width:100%;font-weight:bold;"
        >Crea tu usuario</span>
        <v-card-text>
          <v-form style="max-width:400px;margin-top:25px" ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    style="border-radius: 8px;"
                    label="Nombre completo"
                    v-model="nombre"
                    maxlength="60"
                    hint="Nombre y apellidos como aparecen en su identificación"
                    clearable
                    filled
                    :rules="rulesNombre"
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>

                  <v-text-field
                    style="margin-top:8px;border-radius: 8px;"
                    label="Correo electrónico"
                    v-model="correo"
                    maxlength="60"
                    hint="correo@domain.com"
                    clearable
                    filled
                    dense
                    :rules="rulesCorreo"
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>

                  <v-text-field
                    style="margin-top:8px;border-radius: 8px;"
                    label="Celular"
                    v-model="telefono"
                    maxlength="10"
                    hint="Ingresa tú teléfono"
                    clearable
                    filled
                    dense
                    type="tel"
                    v-mask="'(##) #### ####'"
                    @keyup="isNumber"
                    :rules="phoneRules"
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>

                  <v-text-field
                    style="margin-top:8px;border-radius: 8px;"
                    label="Contraseña"
                    :type="show1 ? 'text' : 'password'"
                    v-model="contrasenia"
                    maxlength="12"
                    hint="Ingresa tú contraseña"
                    clearable
                    filled
                    dense
                    :rules="contraseniaRules"
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-checkbox style="display:none"
                    v-model="terminos"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="Do you agree?"
                    required
                  ></v-checkbox>

                  <input type="checkbox" id="checkbox" v-model="terminosAux" @change="terminos=!terminos" />
                  <div style="margin-left:25px;margin-top:-22px">
                    <a href="#" @click="terminosUrl">Terminos y condiciones de privacidad</a>
                  </div>
                </v-col>

              </v-row>

              <v-row>
               <v-col  cols="6" style="text-align:right;width:100%">
               <!-- <router-link :to="{name: 'proceso'}" style="text-align: center;"> -->
               <router-link :to="{name: 'registro'}" style="width: 100%;text-align: center;">
                  <v-btn
                    rounded
                    class="mr-4"
                    color="red"
                    style="width:100px;font-size:21px;margin-top:10px;color:white;text-transform: capitalize;display: inline-block;"
                    :disabled="!valid"
                    @click="registrar"
                  >Aceptar</v-btn>
                </router-link>
          <!-- </router-link> -->

           
          </v-col>
          <v-col cols="6">
              <!-- <router-link :to="{name: 'home'}" style="text-align: center;"> -->
             <div style="width: 100%;text-align: center;">
                  <v-btn rounded class="" color="blue"  @click="sendHome"
                  style="width:110px;font-size:21px;margin-top:10px;color:white;text-transform: capitalize;display: inline-block;" 
                  >Cancelar</v-btn>
                  </div>
          <!-- </router-link> -->
          </v-col>
            </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- Pago pronto -->
      <v-card v-if="nuevoFlujo" >
          <CreditCardForm></CreditCardForm>
         
          <div style="font-weight: bold;color: cornflowerblue;font-size: small;padding-left:33px;margin-top: -10px;">Datos de acceso a Utax Me</div>
          <v-form
                ref="form" dense
                v-model="valid"
                lazy-validation
                style="padding-left: 35px;padding-right: 35px;"
              >

              <v-text-field
              dense
                  v-model="email"
                  :rules="emailRules"
                  label="Correo electrónico"
                  maxlength="60"
                  required
                  style="margin-bottom:10px;margin-top:8px"
                ></v-text-field>

                <v-text-field dense
                  v-model="contrasenia"
                  :counter="10"
                  :rules="contraseniaRules"
                  maxlength="10"
                  type="password"
                  label="Contraseña"
                  required  style="margin-bottom:10px"
                ></v-text-field>

                

                <v-text-field dense
                  v-model="phone"
                  :rules="phoneRules"
                   :counter="10"
                  maxlength="10"
                  label="Celular"
                  required  style="margin-top:0px;margin-bottom:13px"
                ></v-text-field>
      
               
              </v-form>

          <v-card-actions style="    overflow-x: hidden;background-color: black;">
            <v-row  align="center" justify="space-around">
               <v-btn  color="white lighten-2"  text  @click="sendHome"  >&#60; Salir</v-btn>
               <v-btn color="white lighten-2" text  @click="goToPagar" >Pagar</v-btn>
            </v-row>
           
          </v-card-actions>
      </v-card >
    </v-dialog>

     <v-dialog v-model="dialogoError" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span
            class="headline"
            style="width:100%;text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:36px;font-weight:bold;word-break:break-word;"
          >Su registro no cuenta con los parámetros adecuados :(</span>
          <span style="    text-align: center;color: red; width: 100%;">{{this.$store.state.paqueteNuevoFlujo}}</span>
          <div id="mensajeMail" style="text-align:center;font-size:20px;margin-top:10px;word-break: break-word;line-height: 1.5rem;"
          >{{mensajeMail}}</div>
        </v-card-title>
        <v-card-text>
          <v-form style ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container>
              <v-row>
                <div style="width:100%;text-align:center;">
                  <v-icon color="green" style="font-size:44px">sentiment_very_dissatisfied</v-icon>
                </div>
                <!-- <router-link :to="{name: 'home'}" style="width: 100%;text-align: center;"> -->
                  <div style="width: 100%;text-align: center;">
                  <v-btn
                    rounded
                    color="red"
                    style="width:250px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;"
                    :disabled="!valid"
                    @click="sendHome"
                  >Volver a intentar</v-btn>
                  </div>
                <!-- </router-link> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoConfirmaCorreo" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span
            class="headline"
            style="width:100%;text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:36px;font-weight:bold;word-break:break-word;"
          >Gracias por registrarte en Utax Me</span>
          <div id="mensajeMail" style="text-align:center;font-size:20px;margin-top:10px;word-break: break-word;line-height: 1.5rem;"
          >{{mensajeMail}}</div>
        </v-card-title>
        <v-card-text>
          <v-form style ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container>
              <v-row>
                <div style="width:100%;text-align:center;">
                  <v-icon color="green" style="font-size:44px">mail</v-icon>
                </div>
                <!-- <router-link :to="{name: 'home'}" style="width: 100%;text-align: center;"> -->
                  <div style="width: 100%;text-align: center;">
                  <v-btn
                    rounded
                    color="red"
                    style="width:150px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;"
                    :disabled="!valid"
                    @click="sendHome"
                  >Aceptar</v-btn>
                  </div>
                <!-- </router-link> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogoConfirmaCorreoNuevo" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span
            class="headline"
            style="width:100%;text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:36px;font-weight:bold;word-break:break-word;"
          >!Bienvenido a Utax Me!</span>
          <div id="mensajeMail" style="text-align:left;font-size:20px;margin-top:10px;word-break: break-word;line-height: 1.5rem;"
          ><br>
          <ul>
            <li style="margin-top: 10px;">Su pago se ha realizado de forma exitosa</li>
            <li > {{mensajeMail}}</li>
            <li style="margin-top: 10px;">En breve nos pondremos en contacto contigo para continuar con el proceso de registro.</li>
          </ul>
         
          </div>
        </v-card-title>
        <v-card-text>
          <v-form style ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container>
              <v-row>
          
                  <div style="width: 100%;text-align: center;">
                  <v-btn
                    rounded
                    color="red"
                    style="width:150px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;"
                    :disabled="!valid"
                    @click="sendHome"
                  >Aceptar</v-btn>
                  </div>
                <!-- </router-link> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>




    <v-snackbar
      v-model="snackbarNewFlow"
    >
      {{ textSnack }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarNewFlow = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>



     <v-overlay :value="this.$store.state.loading" style="z-index:90000">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


  </div>



  
</template>

<script>
import router from "../router";
//import { VueMaskDirective } from "v-mask";
import CreditCardForm from '../views/CreditCardFormX.vue';
import operations from '../operations';

export default {
 /* directives: {
    mask: VueMaskDirective
  },*/
  components:{
     CreditCardForm
    },
  name: "Registro",

  data: () => ({
    dialogoConfirmaCorreoNuevo:false,
    mensajeMail:"",
    contrasenia:'',
    phone:'',
    textSnack:'',
    snackbarNewFlow:false,
    originId:'',
    opcion:'',
    productId:'',
    monto:0.0,
    dialogoError:false,
    nuevoFlujo:false,
    tradicional:false,
    valid: true,
    name: '',
    nameRules: [
        v => !!v || 'Contraseña es requerida',
        v => (v && v.length <= 8) || 'Contraseña debe ser menor a 8 caracteres',
      ],
    contraseniaRules: [
        v => !!v || 'Contraseña es requerida',
        v => (v && v.length >= 8) || 'Contraseña debe ser mayor a 8 caracteres',
      ],
    email: '',
    emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
      ],
    phoneRules: [
        v => !!v || 'Teléfono es requerido',
        v => /^[0-9]+$/g.test(v) || 'Teléfono debe ser valido, solo dígitos',
        v => v.length == 10 || "Longitud debe ser de 10 caracteres",
        
      ],
    select: null,
    items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
    ],
      checkbox: false,


    terminosAux:false,
    show1: false,
    valid: true,
    lazy: false,
    dialogoRegistro: true,
    dialogoConfirmaCorreo: false,
    nombre: "",
    terminos: false,
    rulesTerminos: [
      value => !!value || "Acepte los terminos y condiciones."
    ],
    condiciones: false,
    rulesNombre: [
      value => !!value || "Requerido.",
      value => (value && value.length >= 3) || "Min 3 caracteres"
    ],
    correo: "",
    rulesCorreo: [
      value => !!value || "Requerido.",
      value => /.+@.+\..+/.test(value) || "El correo debe ser válido"
    ],
    telefono: "",
    rulesTelefono: [
      value => !!value || "Requerido.",
      value =>
        (value && value.length >= 10) || "El teléfono debe ser de 10 dígitos"
    ],
    nacionalidad: "",
    rulesNacionalidad: [
      value => !!value || "Requerido.",
      value => (value && value.length >= 3) || "Min 8 caracteres"
    ],
    contrasenia: "",
    rulesContrasenia: [
      value => !!value || "Requerido.",
      value => (value && value.length >= 8) || "Min 8 caracteres"
    ],
    contrasenia2: "",
    rulesContrasenia2: [
      value => !!value || "Requerido.",
      value => (value && value.length >= 3) || "Min 8 caracteres"
    ]
  }),
  methods: {
    isNumber: function($event) {
       //alert($event.which);
       let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
       
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
        }
    },
    checkboxmethod() {
      this.terminos = !this.terminos;
    },
    terminosUrl() {
      window.open(
        "https://www.utaxme.com/terminos-y-condiciones",
        "_blank"
      );
    },
    sendHome(){
 
      window.location = 'https://www.utaxme.com';
    },
    async registrar() {
      this.$store.state.loading=true;
    console.log("::::::::::::::::tipo flujo  true es nuevo, false es el anterior::::::::");
    console.log(this.$store.state.nuevoFlujo);
     // console.log("preparando envio de email:::" +this.$store.state.cliente.cardName + "-" + this.email + "-" + this.contrasenia + "-" + this.phone.trim().replace("(", "").replace(")", "").replace(" ", ""));
     
      //set data client to store
      if(this.$store.state.nuevoFlujo){
          this.$store.state.cliente.nombre = this.$store.state.cliente.cardName;
          this.$store.state.cliente.email = this.email;
          this.$store.state.cliente.password = this.contrasenia;
          this.$store.state.cliente.celular = this.phone.trim().replace("(", "").replace(")", "").replace(" ", "");
      }else{
          this.$store.state.cliente.nombre = this.nombre;
          this.$store.state.cliente.email = this.correo;
          this.$store.state.cliente.password = this.contrasenia;
          this.$store.state.cliente.celular = this.telefono.trim().replace("(", "").replace(")", "").replace(" ", "");
      } 
     
      

       try {
              var params={
                    email: this.$store.state.cliente.email.trim().toLowerCase(),
                    nombre:this.$store.state.cliente.nombre.toUpperCase(),
                    originId: this.originId,
                    password:  this.$store.state.cliente.password.trim(),
                    telefono: this.$store.state.cliente.celular.trim().replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace(" ", "")
                }
                console.log(params);
                const response = await operations.register(params);
                console.log(response);

                if (response.status == "200") {
                    if(this.$store.state.nuevoFlujo){
                       this.guardaQuestionario();
                    }else{
                      this.$store.state.loading=false;
                      this.mensajeMail="Por favor confirma tu correo electrónico";
                     // document.getElementById("mensajeMail").innerText="Por favor confirma tu correo electrónico";
                      this.dialogoRegistro = false;
                      this.dialogoConfirmaCorreo = true;

                    }
                    
                   
                }



            } catch (error) {
             
                console.log(error);

                 this.$store.state.loading=false;
                    
                 //this.dialogoRegistro = false;
                 //this.dialogoConfirmaCorreo = true;



               
                var respuesta=error.response.data;
                 if(respuesta.code=="500" &&  (respuesta.response == "Usuario no activo en la plataforma" || respuesta.response == "No coinciden las credenciales" )){
                   this.snackbarNewFlow=true;

                    if(this.$store.state.nuevoFlujo){
                        this.textSnack="Este correo electrónico ya se encuentra registrado, se procederá a realizar el pago.";
                         this.guardaQuestionario();
                    }else{
                        this.textSnack="Este usuario ya se encuentra registrado.";
                    }
                  
                }else if(respuesta.code=="500" && respuesta.response == "El usuario ya está registrado en la plataforma"){
                    this.snackbarNewFlow=true;
                    
                    if(this.$store.state.nuevoFlujo){
                        this.textSnack="Este correo electrónico ya se encuentra registrado, se procederá a realizar el pago.";
                         this.guardaQuestionario();
                    }else{
                        this.textSnack="Este usuario ya se encuentra registrado.";
                    }

                }else if( (respuesta.response).startsWith("could not extract")){
                     this.$store.state.loading=false;
                     this.dialogoRegistro = false;
                     this.snackbarNewFlow=true;
                     this.textSnack="El servicio esta temporalmente inactivo";
                      console.log("El servicio esta temporalmente inactivo");
                      }
                else{
                  this.$store.state.loading=false;
                  this.dialogoRegistro = false;
                  this.textSnack=respuesta.response;
                  console.log(respuesta.response);
                  } 
            }
    },
     async guardaQuestionario(){
  
         try{
           console.log("guarda questionario...");
            var calcTotal = this.$store.state.montoNuevoFlujo.replace(",","");//<--- 116%
          
           var calcSubTotal = parseFloat(calcTotal)/1.16 ;//<---- 100%
           var ivaCosto = parseFloat(calcTotal)-parseFloat(calcSubTotal);

            const responseQuestionary = await operations.questionary({ 
              ciudad: 0,
              comentarios: "",
              email: this.$store.state.cliente.email,
              estado: 0,
              idCuestionario: 0,
              idProspecto: 0,
              ivaCotizacion: parseFloat(ivaCosto.toFixed(2)),
              numPropiedades:1,
              numUsuarios: 0,
              pagarDespues: true,
              paisOrigen: 0,
              paqueteId: this.productId,
              propuestaId: 0,
              resideMexico: true,
              rfc: '',
              subtotalCotizacion: parseFloat(calcSubTotal.toFixed(2)),
              tieneFIEL: false,
              tieneRFC: false,
              totalCotizacion: parseFloat(calcTotal)
            });
            
            console.log('respuesta guarda questionario:');
            console.log(responseQuestionary);
            if(responseQuestionary.status == "200"){
                //---------------SEND FINAL EMAIL---------------------------------------------
                   this.procesoPago();
                //-------------------------------------------------------------------------------
            }
          }
          catch(error){
            this.$store.state.loading=false;
            console.log('error transaccion:');
            console.log(error);
            console.log(error.response);
             //---------------NOTIFY TO USER---------------------------------------------
            this.snackbarNewFlow=true;
            this.textSnack=error.response;

             //-------------------------------------------------------------------------------
          }
      },
    goToPagar(){
       this.$store.state.loading=true;
      var validacion = this.$refs.form.validate();

      if(this.validaTC() && validacion){
         console.log("continue to payment");
         this.$store.state.nuevoFlujo=true;
         this.procesPayment();
      }else{
         this.$store.state.loading=false;
        console.log("validation fail!!");
      }
    },
    validate () {
      this.$refs.form.validate();
    },
    reset () {
      this.$refs.form.reset();
    },
    resetValidation () {
       this.$refs.form.resetValidation();
    },
    validaTC(){

       var matches =  this.$store.state.cliente.cardName.match(/(\d+)/);
       if (matches) {
                console.log("no cumple por tener numeros en el nombre");
                 this.$store.state.datosTarjetaValidos=false;
                 return;

       }

        if(this.$store.state.cliente.cardYear!='Año' && this.$store.state.cliente.cardMonth!='Mes' && this.$store.state.cliente.cardCvv!='' && 
          this.$store.state.cliente.cardNumber.length >=16 && this.$store.state.cliente.cardName.trim().length > 5){
        
            //  console.log("si cumple");
              this.$store.state.datosTarjetaValidos=true;
              return true;
        }else{
      
            //  console.log("no cumple");
              this.$store.state.datosTarjetaValidos=false;
               return false;
        }      
    },
    async catalogosProductos(idPaquete) {
            console.log('catalogosProductos....');
            this.$store.state.loading=true;
            this.$store.state.paqueteNuevoFlujo=" identificando paquete...";

            try {
                const response = await operations.catalogosProductos();
                console.log(response);

                if (response.status == "200") {
    
                    this.$store.state.catalogosProductos = response.data;
                    console.log(":::::::::::::");
                    console.log("ok catalogos cargados...");

                    var paquete=response.data.filter(paq => paq.id == idPaquete);
                    //this.$store.state.paqueteNuevoFlujo= paquete.length==0?"[Paquete no identificado]":paquete[0].descripcion;

                    if(paquete.length==0){
                       this.$store.state.paqueteNuevoFlujo="[Paquete no identificado]";

                        this.tradicional=false;
                        this.nuevoFlujo=false;
                        this.dialogoError=true;
                         this.$store.state.loading=false;
                    }else{
                      this.$store.state.paqueteNuevoFlujo=paquete[0].descripcion;
                    }
                      
                    this.$store.state.loading=false;
                }
            } catch (error) {
                console.log(error);
                this.$store.state.loading=false;
                
            }

             
    },
    async procesPayment(){
         console.log('procesPayment....');
           // this.$store.state.loading=true;

            try {
                this.generaToken();
            } catch (error) {
                console.log(error);
                this.$store.state.loading=false;
            }
  
    },
    generaToken(){
         console.log('peticion token...');
       Conekta.setPublicKey('key_Wg73TCokv6Z4S2FfcdocGzw'); //key_GVxxkvvSEr5NyGNMyes7AhQ');

       var tokenParams = {
            "card": {
              "number": this.$store.state.cliente.cardNumber,
              "name": this.$store.state.cliente.cardName,
              "exp_year": this.$store.state.cliente.cardYear,
              "exp_month": this.$store.state.cliente.cardMonth,
              "cvc": this.$store.state.cliente.cardCvv,
              "address": {
                  "street1": "Calle 123 Int 404",
                  "street2": "Col. Condesa",
                  "city": "Ciudad de Mexico",
                  "state": "Distrito Federal",
                  "zip": "12345",
                  "country": "Mexico"
              }
            }
          };
        // Previene hacer submit más de una vez
        //$form.find("button").prop("disabled", true);
        Conekta.Token.create(tokenParams, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
    },
    conektaSuccessResponseHandler(token){
       this.$store.state.loading=true;
        console.log('genere token exitoso');
        console.log(token.id);
        this.$store.state.cliente.tokenPublico=token.id;
        
        
        this.registrar('nuevo');
    },
    conektaErrorResponseHandler(response){
         this.$store.state.loading=false;
         console.log('error al generar token');
         console.log(response);
         this.$store.state.cliente.tokenPublico='';
         
      
            this.textSnack='';
            this.snackbarNewFlow=true;

            if(response.message_to_purchaser=="Your code could not be processed, please try again later")
            this.textSnack="El código no puede ser procesado, intente más tarde";
            else
            this.textSnack=response.message_to_purchaser;

      
        
           //xxx
          // this.$store.state.cliente.tokenPublico="tok_2q2grpNPqtLc83wFs";
          // this.registrar();

         
    },
    async procesoPago(){    
      
    console.log("en procesoPago");

         
           try{
              //Registra cuenta en sistema
              console.log("data bank...");
              const responseAltaBank = await operations.dataBank({ 
                  anioExpira:this.$store.state.cliente.cardYear,
                  cvt:this.$store.state.cliente.cardCvv,
                  mesExpira:this.$store.state.cliente.cardMonth,
                  tarjeta:this.$store.state.cliente.cardNumber,
                  correo: this.$store.state.cliente.email
                });
             
                console.log(responseAltaBank);
              if(responseAltaBank.status == "200"){
                 console.log("Success");
                 //------------------REGISTRA TRANSACCION BANCARIA---------------------------
                 try{//
                    console.log("transaccion bancaria...");
                    var monto = this.$store.state.montoNuevoFlujo.replace(",","");
                    //var monto = (this.$store.state.cliente.total.toString()).replace(",","");
                    const responseTransaction = await operations.transaccion({ 
                      cantidad: 1,
                      celular: this.$store.state.cliente.celular,
                      descripcion:this.$store.state.paqueteNuevoFlujo,
                      email: this.$store.state.cliente.email,
                      idProducto:this.productId,
                      monto: parseFloat(monto).toFixed(2),
                      tokenPublico: this.$store.state.cliente.tokenPublico,
                      username: this.$store.state.cliente.email,
                    });

                    console.log('respuesta transaccion:');
                    console.log(responseTransaction);                    
                    if(responseTransaction.status == "200"){                      
                      
                        //CASO PAGA AHORA------------------------------
      
                        this.paidOff();
                        //---------------------------------------------
                    }
                 }
                 catch(error){
                    this.$store.state.loading=false;
                    console.log('error transaccion:');
                    //console.log(error);
                    console.log(error.response);
                    
                      this.textSnack='';
                      this.snackbarNewFlow=true;
                      this.textSnack=error.response.data.response;
                     //xxx
                     // this.paidOff();
                    
                    
                    
                 }
                 //------------------------------------------------------------------------------
              }
              else if(responseAltaBank.status == "204"){
                 console.log("204");
                  
              }else{
               console.log("otro codigo");
              }
           }catch(error){
             
             console.log(error);
             console.log(error.response.data);
             this.steppagar=3;
             //alert(error.response.data.response);
               if(error.response.status == 500){
                    if(error.response.data.response=="No coinciden las credenciales"){
                      
                    }else if( (error.response.data.response).startsWith("could not extract")){
                      
                    }else{ //if( (error.response.data.response).startsWith("El usuario no está registrado")){
                       this.dialogoLogin=true;
                    } 
              }
           }  
    },
    async paidOff(){

       console.log("paid off...");


       
        try{

            const responsePaidOff = await operations.paidOff({ 
              username: this.$store.state.cliente.email
            });
            this.$store.state.loading=false;
            console.log('respuesta responsePaidOff:');
            console.log(responsePaidOff);

            //cierra modal nuevo flujo
            this.$store.state.nuevoFlujo=false;
            //muestra modal final nuevo flujo
            this.dialogoConfirmaCorreoNuevo=true;
            this.dialogoRegistro=false;
            this.mensajeMail="Enviamos los detalles del paquete que contrataste al correo " + this.$store.state.cliente.email;
            
          }
          catch(error){
             this.$store.state.loading=false;
            console.log('error transaccion responsePaidOff:');
            console.log(error);
            console.log(error.response);
            this.textSnack='';
            this.snackbarNewFlow=true;
             this.textSnack=error.response.data.response;
             //---------------NOTIFY TO USER---------------------------------------------
                  

             //-------------------------------------------------------------------------------
          }
    }, 
  
  },
  created() {

    //http://localhost:8080/#/registro?originid=banorte&opcion=1&monto=MjM3Ljk4&productId=3

   // console.log('At this point, events and lifecycle have been initialized.');
    //this.snackbarNewFlow=false;
     this.dialogoError=false;
    try{
      
        console.log(this.$route.query);
        this.originId=this.$route.query.originId==undefined ? this.$route.query.originid : this.$route.query.originId;
        this.opcion=this.$route.query.opcion==undefined ? '0' : this.$route.query.opcion;


        if(this.opcion=='1' && this.originId !=undefined  && this.$route.query.monto !=undefined  && this.$route.query.productId !=undefined ){
            this.tradicional=false;
            this.nuevoFlujo=true;
            this.$store.state.montoNuevoFlujo= atob(this.$route.query.monto);//atob
            this.opcion=this.$route.query.opcion;
            this.productId=this.$route.query.productId;

            //call api to get package catalog
            this.catalogosProductos(this.productId);
            //console.log('Parametros::::  originId:'+this.originId+' ,monto:'+ this.$store.state.montoNuevoFlujo+' ,opcion:'+ this.opcion+' ,productId:'+ this.productId);
        }else if(this.opcion=='0' && this.originId=="utaxme"){
           this.tradicional=true;
            this.nuevoFlujo=false;
            this.dialogoError=false;
            this.$store.state.loading=false;

        }else{
            this.tradicional=false;
            this.nuevoFlujo=false;
            // this.snackbarNewFlow=true;
            this.dialogoError=true;
            this.$store.state.loading=false;
        }
        
        //_originId = this.$route.query.originId;
      }catch(error){
        console.log("El parametro originid no es válido...");
      }
  },
  
  }
</script>
<style scoped>
input[type="checkbox"]:after {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 7px solid #d1d3d1;
  z-index: 1;
}

input[type="checkbox"]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 8px solid #013872;
  z-index: 1;
}


</style>